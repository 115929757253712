import {SpendFilterType} from "@/shared/ui/CasesSpendFIlter/CasesSpendFilter";

export interface ProfileResponse {
    steamId: string,
    name: string,
    icon: string,
    tradeLink: string,
    bestCase: BestCaseResponse,
    bestItem: BestDropResponse
    counters: CountersResponse,
}

export interface SellAllResponse {
    sellPrice: number
}

export interface WithdrawResponse {
    status: boolean,
    message: string
}

export interface CountersResponse {
    allCount: number,
    casesCount: number,
    contractCount: number,
    upgradeCount: number,
    bonusCount: number
}

export interface SoldItemsRequest {
    ids: number[]
}

export interface WithdrawItemRequest {
    inventoryItemId: number
}


export enum FromItem {
    Case = 'CASE',
    Upgrade = 'UPGRADE',
    Contract = 'CONTRACT',
    Bonus = 'PAYMENT_BONUS_CASE'
}

export enum StatusItem {
    Shipped = 'SHIPPED',
    Sold =  'SOLD',
    Waiting = 'WAITING',
    Hands = 'HANDS',
    Upgrade = 'UPGRADE',
    Contract = 'CONTRACT'
}

export interface ProfileItemsRequest {
    steamId: string,
    page: number,
    size: number,
    body?: {
        rarity?: string,
        from?: string,
        sortingType?: string,
        sortingDirection?: string,
        spend?: SpendFilterType,
        status?: StatusItem
    }
}

export interface ProfileItem {
    inventoryId: number,
    image: string,
    itemName: string,
    itemType: string,
    stattrak: boolean,
    rarity: string,
    quality: string,
    price: number,
    status: StatusItem,
    from: FromItem,
    caseInfo?: CaseInfoResponse
}

export interface CaseInfoResponse {
    id: number,
    name: string,
    image: string
}

export interface BestCaseResponse {
    id: string,
    name: string,
    image: string
}

export interface BestDropResponse {
    image: string,
    rarity: string,
    price: number
}

export interface Notification {
    id: string,
    title: string,
    date: string,
    message: string,
    hasButton: boolean,
    buttonLink: string,
    buttonTitle: string,
    unread: boolean
}

export interface ReadNotifications {
    ids: string[]
}

export interface UpdateTradeLink {
    tradeLink: string
}