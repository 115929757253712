import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';

import { liveHeaderReducer } from '@/entities/LiveHeader/model/slice/sliceLiveHeader';
import {subHeaderReducer} from "@/entities/SubHeader/slice/sliceSubHeader";

import { StateSchema } from '@/app/providers/StoreProvider/config/StateSchema';
import { rtkApi } from '@/shared/api/rtkApi';
import {userReducer} from "@/entities/User";

export function createReduxStore(initialState?: StateSchema) {
  const rootReducers: ReducersMapObject<StateSchema> = {
    liveHeader: liveHeaderReducer,
    subHeader: subHeaderReducer,
    user: userReducer,
    [rtkApi.reducerPath]: rtkApi.reducer
  };

  return configureStore({
    reducer: rootReducers,
    devTools: __ENV__ === 'development',
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(rtkApi.middleware)
  });
}
