import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './LiveHeaderItem.module.scss'
import {LiveHeaderResponse, WonFrom} from "@/entities/LiveHeader/model/types/liveHeader";
import {UserIcon} from "@/shared/ui/UserIcon/UserIcon";
import {Link} from "react-router-dom";
import ContractIcon from "@/assets/svg/header/contracts.svg"
import UpgradeIcon from "@/assets/svg/header/upgrades.svg"

export enum Rarirty {
    Consumer = 'Consumer',
    Industrial = 'Industrial',
    MilSpec = 'Mil-Spec',
    Restricted = 'Restricted',
    Classified = 'Classified',
    Covert = 'Covert',
    Extraordinary = 'Extraordinary',
}

interface LiveHeaderItemProps {
    className?: string,
    item: LiveHeaderResponse
}
export const LiveHeaderItem = (props: LiveHeaderItemProps) => {
    const { item , className} = props;

    const hoverBlockFrom = () => {
        switch (item.from) {
            case WonFrom.CONTRACT:
                return (<Link className={cls.CaseLink} to={`/contracts`}>
                    <ContractIcon className={cls.BlockFrom} width={68} height={68}/>
                </Link>)
            case WonFrom.UPGRADE:
                return (<Link className={cls.CaseLink} to={`/upgrades`}>
                    <UpgradeIcon className={cls.BlockFrom} width={68} height={68}/>
                </Link>)
            case WonFrom.PAYMENT_BONUS_CASE:
                return (<Link className={cls.CaseLink} to={`/bonus_payment_case/${item.caseId}`}>
                    <img
                        className={cls.BlockFrom}
                        src={item.caseImage}
                        width={68}
                        height={68}
                    />
                </Link>)
            case WonFrom.CASE:
                return (<Link className={cls.CaseLink} to={`/case/${item.caseId}`}>
                    <img
                        className={cls.BlockFrom}
                        src={item.caseImage}
                        width={68}
                        height={68}
                    />
                </Link>)
        }
    }

    return (
        <div className={classNames(cls.LiveHeaderItem, {}, [className, cls[item.item.rarity]])}>
            <div className={classNames(cls.LiveHeaderMain, {}, [])}>
                <img
                    className={cls.WeaponImage}
                    src={item.item.image}
                    alt="skin"
                />
                {item.item.stattrak &&
                <div className={cls.Stattrak}>
                    <span>
                        StatTrak™
                    </span>
                </div>
                }
            </div>
            <div className={classNames(cls.LiveHeaderItemHover)}>
                <Link to={`/profile/${item.user.steamId}`} className={cls.UserBlock}>
                    <UserIcon height={40} width={40} className={cls.UserIcon} src={item.user.icon}/>
                    <span className={cls.UserName}>
                        {item.user.name}
                    </span>
                </Link>
                {hoverBlockFrom()}
            </div>
        </div>
    );
};
