import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './SubHeaderUserIcon.module.scss'
import Arrow from '@/assets/svg/subheader/arrow.svg'
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "@/entities/User";

interface UserIconProps {
    className?: string,
    src: string,
    steamId: string
}
export const SubHeaderUserIcon = (props: UserIconProps) => {
    const { className , src, steamId} = props;
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>();
    const logoutOnClick = () => {
        dispatch(logout())
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setIsOpen]);

    return (
            <div ref={ref} onClick={() => setIsOpen(!isOpen)} className={classNames(cls.UserIcon, {}, [className])}>
                <Link className={cls.ProfileButton} to={`profile/${steamId}`}>
                    <img className={cls.Icon} src={src}/>
                </Link>
            </div>
    );
};
