import { createAsyncThunk } from '@reduxjs/toolkit';

import { User, userActions } from '@/entities/User';
import { $api } from '@/shared/api/api';

export const logout = createAsyncThunk<User>('user/logout', async (_, thunkAPI) => {
  try {
    const response = await $api.get<User>('/auth/logout');

    if (response.status !== 200) {
      throw new Error();
    }

    thunkAPI.dispatch(userActions.setAuthData(null));
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('error');
  }
});
