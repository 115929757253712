import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './BonusPaymentCasePage.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetBonusPaymentCaseQuery,
    useOpenCaseMutation, useOpenPaymentBonusCaseMutation
} from "@/entities/Case/api/caseApi";
import React, {useEffect, useState} from "react";
import {ProfileItem} from "@/entities/Profile/model/types/profile";
import {useSoldItemsMutation} from "@/entities/Profile/api/profileApi";
import {getProfile} from "@/entities/User/model/selectors/getUserAuthData/getUserAuthData";
import {liveHeaderActions} from "@/entities/LiveHeader/model/slice/sliceLiveHeader";
import {toast} from "react-toastify";
import {userActions} from "@/entities/User";
import Background from "@/assets/svg/case/background.svg";
import ReturnIcon from "@/assets/svg/case/return-back.svg";
import {CaseItems} from "@/pages/CasePage/ui/CaseItems/CaseItems";
import {
    BonusPaymentRouletteWheel
} from "@/pages/BonusPaymentCasePage/ui/BonusPaymentRouletteWhell/BonusPaymentRouletteWheel";
import {BonusPaymentCase} from "@/pages/BonusPaymentCasePage/ui/BonusPaymentCase/BonusPaymentCase";


interface BonusPaymentCasePageProps {
    className?: string,
}
export const BonusPaymentCasePage = (props: BonusPaymentCasePageProps) => {
    const { className } = props;
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const {data, isLoading, isError} = useGetBonusPaymentCaseQuery(id);
    const [pageState, setPageState] = useState<number>(1);
    const [factor, setFactor] = useState<number>(1)
    const [award, setAward] = useState<ProfileItem>()
    const [caseOpenMutation, {error}] =  useOpenPaymentBonusCaseMutation();
    const [isFast, setIsFast] = useState<boolean>();
    const [soldItemsMutations] = useSoldItemsMutation();
    const profile = useSelector(getProfile)

    const openCase = (countOpen: number, isFast: boolean) => {
        !isFast && dispatch(liveHeaderActions.setIsPaused(true));
        const toastId = toast.loading("Открытие кейса", {})
        caseOpenMutation(id).unwrap()
            .then((award) => {
                toast.update(toastId, {render: "Кейс открыт", type: "success", autoClose: 1000, isLoading: false});
                setAward(award);
                setIsFast(isFast)
                setPageState(1)
                setPageState(2)
            })
            .catch((error) => {
                dispatch(liveHeaderActions.setIsPaused(false))
                if (error.status === 403) {
                    if (error.data?.message) {
                        toast.update(toastId, {render: error.data.message, type: "error", autoClose: 1000, isLoading: false});
                    } else {
                        toast.update(toastId, {render: `Необходима авторизация`, type: "error", autoClose: 1000, isLoading: false});
                    }
                } else {
                    toast.update(toastId, {render: `Техническая ошибка, попробуйте снова`, type: "error", autoClose: 1000, isLoading: false});
                }
            })
    }

    const soldItem = (id: number) => {
        const toastId = toast.loading("Продажа предмета", {})
        soldItemsMutations({ids: [id]})
            .unwrap()
            .then(() => {
                dispatch(userActions.plusBalance((award.price)));

                toast.update(toastId, {render: "Предмет продан", type: "success", autoClose: 1000, isLoading: false});
            })
            .catch(() => {
                toast.update(toastId, {render: `Техническая ошибка, попробуйте снова`, type: "error", autoClose: 1000, isLoading: false});
            });
    }

    const sellAll = () => {
        const toastId = toast.loading("Продажа предметов", {})
        soldItemsMutations({ids: [award.inventoryId]})
            .unwrap()
            .then(() => {
                dispatch(userActions.plusBalance((award.price)));
                toast.update(toastId, {render: "Предметы проданы", type: "success", autoClose: 1000, isLoading: false});
                setPageState(1)
                setAward(undefined)
            })
            .catch(() => {
                toast.update(toastId, {render: "Техническая ошибка, попробуйте снова", type: "error", autoClose: 1000, isLoading: false});
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pageState])

    if (pageState === 1) {
        return (
            <div className={classNames(cls.BonusPaymentCasePage, {}, [className])}>
                {/*<audio ref={sound_CaseOpen} preload="true">*/}
                {/*    <source src="/sounds/Case/Case_Open.mp3" type="audio/mpeg" />*/}
                {/*    <source src="/sounds/Case/Case_Open.ogg" type="audio/ogg" />*/}
                {/*</audio>*/}
                <Background className={classNames(cls.Background, {}) }/>
                <div className={cls.UpperMenu}>
                    <div onClick={() => navigate(-1)} className={cls.ReturnBack}>
                        <ReturnIcon/>
                        <span>вернуться назад</span>
                    </div>
                </div>
                {data && <BonusPaymentCase
                               paymentSumForAvailable={data.bonusPaymentCase.paymentSumForAvailable}
                               secondsLeft={data.secondsLeft}
                               available={data.bonusPaymentCase.available}
                               currentFactor={factor}
                               openCase={openCase}
                               className={cls.Case}
                               todayPaymentSum={data?.todayPaymentSum}
                               caseItem={data.bonusPaymentCase} />}
                {data?.bonusPaymentCase?.items && <CaseItems className={cls.CaseItems} items={data.bonusPaymentCase.items} />}
            </div>
        );
    }
    if (pageState === 2 && award) {
        return (
            <div className={classNames(cls.BonusPaymentCasePage, {}, [className])}>
                <Background className={classNames(cls.Background, {}) }/>
                {data && <BonusPaymentRouletteWheel sellAll={sellAll}
                                        isFast={isFast}
                                        casePrice={data.bonusPaymentCase.price}
                                        currentFactor={factor}
                                        changeFactor={setFactor}
                                        openCase={openCase}
                                        className={cls.RouletteWheel}
                                        award={award}
                                        items={data.bonusPaymentCase.items}
                                        soldItem={soldItem}
                />}
                {data && <CaseItems className={cls.CaseItems} items={data.bonusPaymentCase.items} />}
            </div>
        )
    }
};

export default BonusPaymentCasePage;