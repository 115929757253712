import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SubHeaderSchema} from "@/entities/SubHeader/types/subHeader"

const initialState : SubHeaderSchema = {
    sound: {
        volume: 1
    }
}

export const subHeaderSlice = createSlice({
    name: "subHeader",
    initialState,
    reducers: {
        setSoundVolume: (state, action: PayloadAction<number>) => {
            state.sound.volume = action.payload
            localStorage.setItem("sound_volume", action.payload.toString())
        }
    }
})

export const { actions: subHeaderActions } = subHeaderSlice
export const { reducer: subHeaderReducer } = subHeaderSlice