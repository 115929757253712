import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './HeaderSwitcherButton.module.scss'

interface HeaderSwitcherButtonProps {
    className?: string,
    icon: React.VFC<React.SVGProps<SVGSVGElement>>,
    title: string,
    active: boolean,
    onClick: () => void

}
export const HeaderSwitcherButton = (props: HeaderSwitcherButtonProps) => {
    const {icon: Icon, title, className, active, onClick } = props;
    return (
        <div className={
            classNames(cls.HeaderSwitcherButton,
                {[cls.active]: active},
                [className])}
             onClick={onClick}
        >
            <Icon className={classNames(cls.Icon)}/>
            <span className={cls.Text} >{title}</span>
        </div>
    );
};
