import {rtkApi} from "@/shared/api/rtkApi";
import {StatisticResponse} from "@/entities/Statistic/model/types/statistic";

const statisticApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getStatistic: build.query<StatisticResponse, void>({
            query: () => ({
                url: `/statistic`,
                method: "GET"
            })
        })
    })
})

export const {
    useGetStatisticQuery
} = statisticApi

