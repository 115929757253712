import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './BonusPaymentRouletteWheel.module.scss'
import {useEffect} from "react";
import {
    HorizontalRouletteWheel
} from "@/pages/CasePage/ui/RouletteWheel/HorizontalRouletteWheel/HorizontalRouletteWheel";
import {VerticalRouletteWheel} from "@/pages/CasePage/ui/RouletteWheel/VerticalRouletteWheel/VerticalRouletteWheel";
import {CaseItem} from "@/entities/Case/model/types/case";
import {ProfileItem} from "@/entities/Profile/model/types/profile";
import {
    BonusPaymentHorizontalRouletteWheel
} from "@/pages/BonusPaymentCasePage/ui/BonusPaymentHorizontalRouletteWheel/BonusPaymentHorizontalRouletteWheel";

interface BonusPaymentRouletteWheelProps {
    className?: string,
    items: CaseItem[],
    award: ProfileItem,
    openCase: (openCount: number, isFast: boolean) => void,
    currentFactor: number,
    sellAll: () => void,
    isFast: boolean,
    changeFactor: (factor: number) => void,
    casePrice: number,
    soldItem: (id: number) => void,
}
export const BonusPaymentRouletteWheel = (props: BonusPaymentRouletteWheelProps) => {
    const {className,
        items,
        award,
        currentFactor,
        changeFactor,
        openCase,
        isFast,
        sellAll,
        casePrice,
        soldItem
    } = props;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (<BonusPaymentHorizontalRouletteWheel
        sellAll={sellAll}
        isFast={isFast}
        currentFactor={currentFactor}
        changeFactor={changeFactor}
        openCase={() => openCase(currentFactor, isFast)}
        award={award}
        className={className}
        items={items}
        casePrice={casePrice}
    />)
};
