import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './CommonButton.module.scss'

interface CommonButtonProps {
    className?: string,
    icon?: React.VFC<React.SVGProps<SVGSVGElement>>,
    text: string,
    onClick: () => void,
    disabled?: boolean,
    mainColor?: boolean
}
export const CommonButton = (props: CommonButtonProps) => {
    const { className,
        icon: Icon,
        text,
        onClick,
        disabled,
        mainColor
    } = props;

    const onClickWrapper = () => {
        if (disabled) {
            return
        }
        onClick()
    }

    return (
        <div onClick={onClickWrapper} className={classNames(cls.CommonButton, {[cls.Disabled]: disabled, [cls.MainColor]: mainColor}, [className])}>
            {Icon && <Icon/>}
            {text}
        </div>
    );
};
