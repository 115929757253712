import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './HorizontalRouletteWheelItem.module.scss'
import {CaseItem} from "@/entities/Case/model/types/case";

interface HorizontalRouletteWheelItemProps {
    className?: string,
    caseItem: CaseItem
}
export const HorizontalRouletteWheelItem = (props: HorizontalRouletteWheelItemProps) => {
    const { className, caseItem } = props;
    return (
        <div className={classNames(cls.HorizontalRouletteWheelItem, {}, [className, [cls[caseItem.rarity]]])}>
            <img className={cls.Item} src={caseItem.image}/>
            <span className={cls.WeaponName}>{caseItem.itemType}</span>
            <span className={cls.ItemName}>{caseItem.itemName}</span>
        </div>
    );
};
