import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './Item.module.scss'
import {CaseItem} from "@/entities/Case/model/types/case";

interface ItemProps {
    className?: string,
    item: CaseItem
}
export const Item = (props: ItemProps) => {
    const { className, item } = props;
    return (
        <div className={classNames(cls.Item, {}, [className, [cls[item.rarity]]])}>
            <img className={cls.Image} src={item.image}/>
            <span className={cls.WeaponName}>{item.itemType}</span>
            <span className={cls.ItemName}>{item.itemName}</span>
        </div>
    );
};
