import { createAsyncThunk } from '@reduxjs/toolkit';

import { liveHeaderActions } from '@/entities/LiveHeader/model/slice/sliceLiveHeader';
import { LiveHeaderItemsResponse } from '@/entities/LiveHeader/model/types/liveHeader';
import { $api } from '@/shared/api/api';

export const fetchLiveHeaderItems = createAsyncThunk<LiveHeaderItemsResponse>(
  'liveHeader/liveHeaderItems',
  async (_, thunkAPI) => {
    try {
      const response = await $api.get<LiveHeaderItemsResponse>('/live_header');

      if (!response.data) {
        throw new Error();
      }

      thunkAPI.dispatch(liveHeaderActions.addItems(response.data));

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('error');
    }
  },
);
