import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './BonusPaymentCase.module.scss'
import {useSelector} from "react-redux";
import {getProfile} from "@/entities/User/model/selectors/getUserAuthData/getUserAuthData";
import {CaseButton} from "@/pages/CasePage/ui/CaseButton/CaseButton";
import {NotAuthButton} from "@/pages/CasePage/ui/NotAuthButton/NotAuthButton";
import {
    BonusPaymentCaseResponse,
} from "@/entities/Case/model/types/case";
import {BonusPaymentTimer} from "@/pages/CasesPage/ui/BonusPaymentCaseGroup/ui/BonusPaymentTimer/BonusPaymentTimer";
import {formatMoney} from "@/shared/lib/formatMoney/formatMoney";

interface BonusPaymentCaseProps {
    className?: string,
    secondsLeft: number,
    todayPaymentSum?: number,
    caseItem: BonusPaymentCaseResponse,
    paymentSumForAvailable: number,
    openCase: (openCount: number, isFast: boolean) => void,
    currentFactor: number,
    available: boolean,
}
export const BonusPaymentCase = (props: BonusPaymentCaseProps) => {
    const { className , caseItem, openCase, currentFactor, secondsLeft, todayPaymentSum, paymentSumForAvailable, available} = props;
    const profile = useSelector(getProfile);

    const canOpen = todayPaymentSum && todayPaymentSum >= paymentSumForAvailable;

    const canOpenAndAvailable = canOpen && available;
    const canOpenAndNotAvailable = canOpen && !available;

    return (
        <div className={classNames(cls.Case, {}, [className])}>
            <span className={cls.CaseName}>
                {caseItem.name}
            </span>
            <div className={cls.ImageWrapper}>
                <img src={caseItem.image} className={cls.Image}/>
            </div>
            <div className={cls.Timer}>
                <BonusPaymentTimer seconds={secondsLeft}/>
            </div>
            <div className={cls.TodayPaymentInfo}>
                {(profile && !canOpen) && <div>
                    <span className={cls.TodayPaymentSumm}>{formatMoney(todayPaymentSum)}</span>{` / `}
                    <span className={cls.TodayPaymentSumm}>{formatMoney(paymentSumForAvailable)}</span>
                    <span>{` до открытия кейса`}</span>
                </div>}
            </div>
            {profile &&
                <>
                    <div className={cls.Buttons}>
                        {canOpenAndAvailable === true &&
                            <>
                                <CaseButton mainColor={true} openCase={() => openCase(currentFactor, false)} title={`открыть бесплатно`}/>
                                <CaseButton openCase={() => openCase(currentFactor, true)} title={`открыть быстро`}/>
                            </>
                        }
                        {canOpenAndNotAvailable === true &&
                            <>
                                <CaseButton disable={true} mainColor={true} openCase={() => openCase(currentFactor, false)} title={`уже открыт`}/>
                                <CaseButton disable={true} openCase={() => openCase(currentFactor, true)} title={`открыть быстро`}/>
                            </>
                        }
                        {!canOpen === true &&
                            <>
                                <CaseButton disable={true} mainColor={true} openCase={() => openCase(currentFactor, false)} title={`не хватает пополнений`}/>
                                <CaseButton disable={true} openCase={() => openCase(currentFactor, true)} title={`открыть быстро`}/>
                            </>
                        }
                    </div>
                </>}
            {!profile &&
                <>
                    <NotAuthButton className={cls.NoAuthButton}/>
                </>
            }
        </div>
    );
};
