import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './InformationBlock.module.scss'

interface InformationBlockProps {
    className?: string,
    icon: React.VFC<React.SVGProps<SVGSVGElement>>,
    title: string,
    value: number

}
export const InformationBlock = (props: InformationBlockProps) => {
    const { className, icon: Icon, title, value } = props;

    const addSpacesToNumber = (value: number): string => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    return (
        <div className={classNames(cls.InformationBlock, {}, [className])}>
            <Icon className={cls.Icon}/>
            <div className={cls.RightSide}>
                <span className={cls.Value}>
                    {addSpacesToNumber(value)}
                </span>
                <span className={cls.Description}>
                    {title}
                </span>
            </div>
        </div>
    );
};
