import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './DownMenu.module.scss'
import CaseIcon from "@/assets/svg/menu/case-icon.svg";
import UpgradeIcon from "@/assets/svg/menu/upgrade-icon.svg";
import ContractIcon from "@/assets/svg/menu/contract-icon.svg";
import PrizeIcon from "@/assets/svg/menu/prize-icon.svg";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {DownMenuButton} from "@/widgets/DownMenu/ui/DownMenuButton/DownMenuButton";

interface DownMenuProps {
    className?: string,
}
export const DownMenu = (props: DownMenuProps) => {
    const { className } = props;
    const [activeTitle, setActiveTitle] = useState<string>();
    const location = useLocation();
    useEffect(() => {
        setActiveTitle(location.pathname)
    }, [location])

    const buttons = [
        {
            icon: CaseIcon,
            href: '/'
        },
        {
            icon: UpgradeIcon,
            href: '/upgrades',
        },
        {
            icon: ContractIcon,
            href: '/contracts',
        },
    ];

    const buttonComponents = buttons.map(({icon: Icon, href}, i: number) => {
        return <DownMenuButton
            key={i}
            href={href}
            icon={Icon}
            active={href === activeTitle}
        />
    })

    return (
        <>
            <div className={classNames(cls.DownMenu, {}, [className])}>
                <div className={cls.Line}/>
                <div className={cls.Buttons}>
                    {buttonComponents}
                </div>
            </div>

        </>
    );
};
