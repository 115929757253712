import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {fetchProfile} from '@/entities/User/model/services/initUserData';

import { User, UserSchema } from '../types/user';

// TODO refact
const initialState: UserSchema = {};
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthData: (state: UserSchema, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
    minusBalance: (state: UserSchema, { payload }: PayloadAction<number>) => {
      state.user.balance = state.user.balance - payload;
    },
    plusBalance: (state: UserSchema, { payload }: PayloadAction<number>) => {
      state.user.balance = state.user.balance + payload;
    },
    logout: (state: UserSchema) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.user = undefined;
        state.isLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.error = 'error';
        state.user = undefined;
        state.isLoading = false;
      });
  },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
