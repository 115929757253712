import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './ExitButton.module.scss'
import {logout} from "@/entities/User";
import {useDispatch} from "react-redux";
import ExitIcon from "@/assets/svg/subheader/exit.svg"

interface ExitButtonProps {
    className?: string,
}
export const ExitButton = (props: ExitButtonProps) => {
    const { className } = props;

    const dispatch = useDispatch();

    const logoutOnClick = () => {
        dispatch(logout())
    }
    return (
        <div onClick={logoutOnClick} className={classNames(cls.ExitButton, {}, [className])}>
            <ExitIcon />
        </div>
    );
};
