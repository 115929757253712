import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './NotAuthButton.module.scss'
import SteamIcon from "@/assets/svg/subheader/steam.svg";
import {Link} from "react-router-dom";

interface NotAuthButtonProps {
    className?: string,
}
export const NotAuthButton = (props: NotAuthButtonProps) => {
    const { className } = props;

    const url = 'https://steamcommunity.com/openid/login?' +
        'openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0' +
        '&openid.identity=http://specs.openid.net/auth/2.0/identifier_select' +
        '&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select' +
        `&openid.return_to=https://leetcase.skin/steam_auth` +
        `&openid.realm=https://leetcase.skin`;

    return (
        <div className={classNames(cls.NotAuthButton, {}, [className])}>
            <div className={cls.Notification}>
                вы не авторизованы
            </div>
            <Link to={url} className={cls.LogIn}>
                <SteamIcon/>
                <span>войти</span>
            </Link>
        </div>
    );
};
