import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './SubHeaderButtonPayment.module.scss'
import PaymentIcon from '@/assets/svg/subheader/payment.svg'
import {formatMoney} from "@/shared/lib/formatMoney/formatMoney";
import {Link} from "react-router-dom";

interface SubHeaderButtonPaymentProps {
    className?: string,
    balance: number;
}
export const SubHeaderButtonPayment = (props: SubHeaderButtonPaymentProps) => {
    const { className, balance } = props;

    return (
        <Link to={'/payments'} className={classNames(cls.SubHeaderButtonPayment, {}, [className])}>
            <PaymentIcon className={cls.Icon}/>
            <span>{formatMoney(balance)}</span>
        </Link>
    );
};
