import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './CaseButton.module.scss'

interface CaseButtonProps {
    className?: string,
    title: string,
    openCase: () => void,
    mainColor?: boolean,
    disable?: boolean
}
export const CaseButton = (props: CaseButtonProps) => {
    const { className, title, openCase, mainColor , disable} = props;
    return (
        <div onClick={openCase} className={classNames(cls.CaseButton, {[cls.MainColor]: mainColor, [cls.Disabled]: disable}, [className])}>
            {title}
        </div>
    );
};
