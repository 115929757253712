import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './SoundInput.module.scss'
import {InputRange} from "@/widgets/SubHeader/ui/SoundButton/ui/InputRange/InputRange";

interface SoundInputProps {
    className?: string,
    onChange: (value: number) => void,
    value: number
}
export const SoundInput = (props: SoundInputProps) => {
    const { className , onChange, value} = props;
    return (
        <div className={classNames(cls.SoundInput, {}, [className])}>
            <InputRange value={value} onChange={onChange}/>
        </div>
    );
};
