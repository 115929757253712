import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './LiveHeader.module.scss'
import {LiveHeaderResponse} from "@/entities/LiveHeader/model/types/liveHeader";
import {LiveHeaderItem} from "@/widgets/Header/ui/LiveHeaderItem";

export interface LiveHeaderItem {
    itemName: string;
    price: number;
    awardPercent: number;
    image: string;
    item: string;
    rarity: string;
    stattrak: boolean;
    userId: string;
}

interface LiveHeaderProps {
    className?: string,
    items: LiveHeaderResponse[],
}
export const LiveHeader = (props: LiveHeaderProps) => {
    const { items, className } = props;
    const liveHeaderItems =  items?.map((item, i) => {
        return <LiveHeaderItem key={i} item={item} />;
    })

    return (
        <div className={classNames(cls.LiveHeader, {}, [className])}>
            {liveHeaderItems}
        </div>
    );
};
