import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './AdditionalInfo.module.scss'
import AdditionalInfoIcon from "@/assets/svg/menu/additional-info.svg";

interface AdditionalInfoProps {
    className?: string,
}
export const AdditionalInfo = (props: AdditionalInfoProps) => {
    const { className } = props;
    return (
        <div className={classNames(cls.AdditionalInfo, {}, [className])}>
            <div className={cls.AdditionalInfoTitle}>
                <AdditionalInfoIcon className={cls.AdditionalInfoIcon} />
                <span>Доп. информация</span>
            </div>
            <ul className={cls.AdditionalInfoUl}>
                <li>
                    <a href="/policy">Пользовательское соглашение</a>
                </li>
                <li>
                    <a href="/contacts">Контакты</a>
                </li>
                <li>
                    <a href="/faq">FAQ</a>
                </li>
            </ul>
        </div>
    );
};
