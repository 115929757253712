import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './CaseItems.module.scss'
import {CaseItem} from "@/entities/Case/model/types/case";
import {Item} from "@/pages/CasePage/ui/CaseItems/ui/Item/Item";

interface CaseItemsProps {
    className?: string,
    items: CaseItem[]
}
export const CaseItems = (props: CaseItemsProps) => {
    const { className, items } = props;

    const itemComponents = items.map((item, i) => {
        return <Item key={i} item={item}/>
    })
    return (
        <div className={classNames(null, {}, [className])}>
            <span className={cls.Title}>
                <span>Предметы в кейсе</span>
            </span>
            <div className={classNames(cls.CaseItems, {}, [])}>
                {itemComponents}
            </div>
        </div>
    );
};
