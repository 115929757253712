import { Client } from '@stomp/stompjs';
import { IMessage } from '@stomp/stompjs/src/i-message';
import { useEffect } from 'react';

export const useLiveHeaderWs = (topic: string, subscribeCallback: (response: IMessage) => void) => {
  useEffect(() => {
    const client = new Client();

    const connectionCallback = () => {
      client.subscribe(topic, subscribeCallback);
    };

    client.configure({
      brokerURL: `${__API_WS__}`,
      onConnect: connectionCallback,
    });

    client.activate();

    return () => {
      client.deactivate();
    };
  }, [topic]);
};
