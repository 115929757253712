import {
  BonusPaymentCaseResponse,
  BonusPaymentCases,
  CaseGroupsResponse,
  CaseItem, CaseResponse,
  CaseWithItems,
  FavoriteResponse, GetBonusPaymentCaseResponse,
  OpenCaseRequest
} from '@/entities/Case/model/types/case';
import { rtkApi } from '@/shared/api/rtkApi';
import {ProfileItem, StatusItem} from "@/entities/Profile/model/types/profile";

const caseApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getCases: build.query<CaseGroupsResponse, void>({
      query: () => ({
        url: `/cases`,
        method: 'GET',
      }),
      providesTags: ['Cases']
    }),
    getBonusPaymentCases: build.query<BonusPaymentCases, void>({
      query: () => ({
        url: `/bonus_payment_cases`,
        method: 'GET',
      }),
      providesTags: ['BonusCases']
    }),
    getBonusPaymentCase: build.query<GetBonusPaymentCaseResponse, string>({
      query: (id: string) => ({
        url: `/bonus_payment_cases/${id}`,
        method: 'GET',
      }),
      providesTags: ['Case']
    }),
    getCase: build.query<CaseWithItems, string>({
      query: (id: string) => ({
        url: `/cases/${id}`,
        method: 'GET',
      }),
      providesTags: ['Cases']
    }),
    openCase: build.mutation<ProfileItem[], OpenCaseRequest>({
      query: ({ caseId, count }) => ({
        url: `/cases/open_case/${caseId}`,
        params: { count },
        method: `GET`,
      }),
      invalidatesTags: ['Inventory', 'Profile']
    }),
    openPaymentBonusCase: build.mutation<ProfileItem, string>({
      query: (caseId: string ) => ({
        url: `/bonus_payment_cases/open_case/${caseId}`,
        method: `GET`,
      }),
      invalidatesTags: ['Inventory', 'Profile', 'Case', 'BonusCases']
    }),
    addToFavorite: build.mutation<FavoriteResponse, string>({
      query: (caseId) => ({
        url: `/cases/favorite/${caseId}`,
        method: `GET`,
      }),
      invalidatesTags: ['Cases']
    }),
  }),
});

export const {
  useGetCasesQuery,
  useGetCaseQuery,
  useOpenCaseMutation,
  useAddToFavoriteMutation,
  useGetBonusPaymentCasesQuery,
  useGetBonusPaymentCaseQuery,
  useOpenPaymentBonusCaseMutation
} = caseApi