import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './Menu.module.scss'
import {DropdownButton} from "@/widgets/Menu/ui/DropdownButton/ui/DropdownButton";
import {LinkButton} from "@/widgets/Menu/ui/LinkButton/ui/LinkButton";
import CaseIcon from "@/assets/svg/menu/case-icon.svg";
import UpgradeIcon from "@/assets/svg/menu/upgrade-icon.svg";
import ContractIcon from "@/assets/svg/menu/contract-icon.svg";
import LogoIcon from "@/assets/svg/menu/logo.svg";
import LogoMiniIcon from "@/assets/svg/menu/logo-mini.svg";
import VkIcon from "@/assets/svg/social/vk.svg";
import TelegaIcon from "@/assets/svg/social/teleg.svg";
import MirIcon from "@/assets/svg/payments/mir.svg";
import BtcIcon from "@/assets/svg/payments/btc.svg";
import SbpPayIcon from "@/assets/svg/payments_page/sbp_pay.svg";
import React, {ReactNode, useEffect, useState} from "react";
import {AdditionalInfo} from "@/widgets/Footer/ui/AdditionalInfo/ui/AdditionalInfo";
import {Link, useLocation} from "react-router-dom";

interface MenuProps {
    className?: string,
    children?: ReactNode;
    setMenuIsOpen: (value: boolean) => void,
    menuIsOpen: boolean
}

export const Menu = (props: MenuProps) => {
    const { className, children, setMenuIsOpen, menuIsOpen} = props;
    const [activeTitle, setActiveTitle] = useState<string>();
    const [isOpen, setOpen] = useState<boolean>(menuIsOpen);
    const location = useLocation();
    useEffect(() => {
        setActiveTitle(location.pathname)
    }, [location])

    const buttons = [
        {
            title: 'Кейсы',
            icon: CaseIcon,
            href: '/'
        },
        {
            title: 'Апгрейды',
            icon: UpgradeIcon,
            href: '/upgrades',
        },
        {
            title: 'Контракты',
            icon: ContractIcon,
            href: '/contracts',
        },
        // {
        //     title: 'Ежедневные призы',
        //     icon: PrizeIcon,
        //     href: '/prize',
        // },
    ];

    const clickOpenMenu = (value: boolean) => {
        setOpen(value);
        setMenuIsOpen(value);
    }

    const buttonComponents = buttons.map((item, i) => {
        return <LinkButton key={i}
                           icon={item.icon}
                           title={item.title}
                           active={item.href === activeTitle}
                           onClick={setActiveTitle}
                           isClose={!isOpen}
                           to={item.href}
        />
    })
    return (
        <div className={classNames(cls.Menu, {[cls.close]: !isOpen}, [className])}>
            <div className={cls.MenuTop}>
                <div className={classNames(cls.DropdownButtonWrapper, {[cls.close]: !isOpen})}>
                    <DropdownButton active={isOpen} onClick={() => clickOpenMenu(!isOpen)}/>
                </div>
                <Link to={"/"} className={cls.LogoWrapper}>
                    {isOpen ?
                    <LogoIcon className={classNames(cls.Logo, {[cls.close]: !isOpen}, [])}/> :
                        <LogoMiniIcon className={cls.LogoMini}/>}
                </Link>
                <div className={cls.LinkButtons}>
                    {buttonComponents}
                </div>
                <div className={classNames(cls.UnderButtonsLine, {[cls.close]: !isOpen})}/>
            </div>
            <div className={classNames(cls.MenuBottom, {[cls.close]: !isOpen})}>
                <div className={cls.Social}>
                    <Link to={"https://vk.com/leetcase"}>
                        <VkIcon/>
                    </Link>
                    <Link to={"https://t.me/leetcase"}>
                        <TelegaIcon/>
                    </Link>
                </div>
                <div className={cls.PowerdBy}>
                    Powered by Steam, a registered trademark of Valve Corporation. Valve, the Valve logo, the Steam logo, Counter-Strike and the Counter-Strike logo are trademarks and/or registered trademarks of Valve Corporation. All other trademarks are property of their respective owners
                </div>
                <div className={cls.Payments}>
                    <MirIcon width={42} height={12} />
                    <SbpPayIcon width={58} height={22} />
                    <BtcIcon width={70} height={16} />
                </div>
                <span className={cls.Copyright}>
                    Copyright © 2023 – 2024 Leet case
                </span>
            </div>
            {children}
        </div>
    );
};
