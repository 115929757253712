import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LiveHeaderSchema } from '@/entities/LiveHeader/model/types/liveHeader';
import { LiveHeaderItemsResponse } from '@/entities/LiveHeader/model/types/liveHeader';

const initialState: LiveHeaderSchema = {
  isPaused: false,
  rare: undefined,
  all: undefined,
  rareQueue: undefined,
  allQueue: undefined,
};
export const liveHeaderSlice = createSlice({
  name: 'liveHeader',
  initialState,
  reducers: {
    setIsPaused: (state: LiveHeaderSchema, { payload }: PayloadAction<boolean>) => {
      state.isPaused = payload;
      if (!payload && state.allQueue && state.allQueue.length > 0) {
        state.all = state.allQueue.concat(state.all);
        state.allQueue = null;
      }
      if (!payload && state.rareQueue && state.rareQueue.length > 0) {
        state.rare = state.rareQueue.concat(state.rare);
        state.rareQueue = null;
      }
    },
    addItems: (state, { payload }: PayloadAction<LiveHeaderItemsResponse>) => {
      if (state.isPaused && payload.rare.length > 0) {
        state.rareQueue = payload.rare.reverse().filter((it) => it != null);
      } else if (payload.rare.length > 0) {
        if (state.rare && state.rare.length > 0) {
          state.rare = payload.rare.reverse().concat(state.rare);
        } else {
          state.rare = payload.rare;
        }
      }
      if (state.isPaused) {
        state.allQueue = payload.all.reverse().filter((it) => it != null);
      } else if (state.all && state.all.length > 0) {
        state.all = payload.all
          .reverse()
          .filter((it) => it != null)
          .concat(state.all);
      } else {
        state.all = payload.all;
      }
    },
  },
});

export const { actions: liveHeaderActions } = liveHeaderSlice;
export const { reducer: liveHeaderReducer } = liveHeaderSlice;
