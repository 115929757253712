import {useEffect, useRef} from "react";
import cls from './InputRange.module.scss'


interface InputRangeProps {
    className?: string,
    onChange: (value: number) => void,
    value: number

}
export const InputRange = (props: InputRangeProps) => {
    const { className, onChange, value} = props;
    const ref = useRef<HTMLInputElement>();

    const onInputProgress = () => {
        const sliderValue = ref.current.value;
        ref.current.style.backgroundImage = `linear-gradient(to right, #f50 ${sliderValue}%, #ccc ${sliderValue}%)`;
    }

    useEffect(() => {
        onInputProgress();
    },[])

    return (
        <input
            className={cls.InputRange}
            ref={ref}
            type='range'
            onChange={(e) => {onChange(parseInt(e.target.value))}}
            onInput={onInputProgress}
            defaultValue={value}
        />
            
    );
};
