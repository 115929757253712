import {rtkApi} from "@/shared/api/rtkApi";

import {
    Notification,
    ProfileItem,
    ProfileItemsRequest,
    ProfileResponse,
    ReadNotifications,
    SellAllResponse,
    SoldItemsRequest,
    StatusItem,
    UpdateTradeLink,
    WithdrawItemRequest,
    WithdrawResponse
} from "@/entities/Profile/model/types/profile";
import {User, userActions} from "@/entities/User";

export const profileApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getProfile: build.query<ProfileResponse, string>({
            query: (steamId: string) => ({
                url: `/profile/${steamId}`,
                method: 'GET',
            }),
            providesTags: ['Profile']
        }),
        getAuthData: build.query<User, void>({
            query: () => ({
                url: `/profile`,
                method: 'GET',
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(userActions.setAuthData(data));
                } catch {
                    // Обработка ошибок
                }
            },
            providesTags: ['Auth']
        }),
        authUser: build.mutation<ProfileResponse, string>({
            query: (params) => ({
                url: `/auth/steam${params}`,
                method: 'GET',
            }),
            invalidatesTags: ['Auth']
        }),
        getProfileItems: build.query<ProfileItem[], ProfileItemsRequest>({
            query: ({steamId, page, size, body}) => ({
                url: `/profile/items/${steamId}?page=${page}&size=${size}`,
                method: 'POST',
                body: body
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.page === 0) {
                    return newItems
                } else {
                    currentCache.push(...newItems)
                }
            },
            forceRefetch({ currentArg, previousArg }){
                return currentArg !== previousArg
            },
            providesTags: ['Inventory']
        }),
        soldItems: build.mutation<void, SoldItemsRequest>({
            query: (request: SoldItemsRequest) => ({
                url: `/inventory_items/sell`,
                body: request,
                method: 'POST',
            })
            ,invalidatesTags: ['Profile'],
            async onQueryStarted({ ids, ...searchParams }, { dispatch }) {
                dispatch(profileApi.util.updateQueryData("getProfileItems", undefined, (draft: ProfileItem[]) => {
                    const itemId = ids[0];
                    const item = draft.find((item) => item.inventoryId === itemId)
                    item.status = StatusItem.Sold;
                }))
            }
        }),
        soldAllItems: build.mutation<SellAllResponse, void>({
            query: (request: void) => ({
                url: `/inventory_items/sell_all`,
                body: request,
                method: 'POST',
            })
            ,invalidatesTags: ['Profile'],
            async onQueryStarted(request, { dispatch }) {
                dispatch(profileApi.util.updateQueryData("getProfileItems", undefined, (draft: ProfileItem[]) => {
                    draft.filter((item) => item.status === StatusItem.Hands).forEach((item) => item.status = StatusItem.Sold)
                }))
            }
        }),
        soldItemsFromContract: build.mutation<ProfileResponse, SoldItemsRequest>({
            query: (request: SoldItemsRequest) => ({
                url: `/inventory_items/sell`,
                body: request,
                method: 'POST',
            })
            ,invalidatesTags: ['Inventory']
        }),
        withdrawItems: build.mutation<WithdrawResponse, WithdrawItemRequest>({
            query: (request: WithdrawItemRequest) => ({
                url: `/inventory_items/withdraw`,
                body: request,
                method: 'POST',
            })
            ,invalidatesTags: ['Profile'],
            async onQueryStarted({ inventoryItemId, ...searchParams }, { queryFulfilled, dispatch }) {
                queryFulfilled.then(result => {
                    if (result.data.status === true) {
                        dispatch(profileApi.util.updateQueryData("getProfileItems", undefined, (draft: ProfileItem[]) => {
                            const itemId = inventoryItemId;
                            const item = draft.find((item) => item.inventoryId === itemId)
                            item.status = StatusItem.Waiting;
                        }))
                    }
                }).catch()
            }
        }),
        getNotifications: build.query<Notification[], void>({
            query: () => ({
                url: `/notifications`,
                method: 'GET'
            }),
        }),
        readNotification: build.mutation<void, ReadNotifications>({
            query: (request: ReadNotifications) => ({
                url: `/notifications/read`,
                body: request,
                method: 'POST'
            }),
            async onQueryStarted({ ids, ...searchParams }, { dispatch }) {
                dispatch(profileApi.util.updateQueryData("getNotifications", undefined, (draft: Notification[]) => {
                    draft
                        .filter((item) => ids.includes(item.id))
                        .forEach((item) => item.unread = false)
                }))
            }
        }),
        updateTradeLink: build.mutation<void, UpdateTradeLink>({
            query: (request: UpdateTradeLink) => ({
                url: `/profile/update_trade_link`,
                body: request,
                method: 'POST'
            }),
            async onQueryStarted({ tradeLink, ...searchParams }, { dispatch }) {
                dispatch(profileApi.util.updateQueryData("getProfile", undefined, (draft: ProfileResponse) => {
                    draft.tradeLink = tradeLink;
                }))
            }
        })
    }),
});


export const {
    useGetProfileQuery,
    useSoldItemsMutation,
    useSoldItemsFromContractMutation,
    useGetNotificationsQuery,
    useReadNotificationMutation,
    useGetProfileItemsQuery,
    useWithdrawItemsMutation,
    useUpdateTradeLinkMutation,
    useSoldAllItemsMutation,
    useAuthUserMutation,
    useGetAuthDataQuery
} = profileApi
