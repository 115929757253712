import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './BonusPaymentHorizontalRouletteWheel.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {CaseItem} from "@/entities/Case/model/types/case";
import {getProfile} from "@/entities/User/model/selectors/getUserAuthData/getUserAuthData";
import {StateSchema} from "@/app/providers/StoreProvider";
import {
    HorizontalRouletteWheelItem
} from "@/pages/CasePage/ui/RouletteWheel/HorizontalRouletteWheel/ui/HorizontalRouletteWheelItem/HorizontalRouletteWheelItem";
import {liveHeaderActions} from "@/entities/LiveHeader/model/slice/sliceLiveHeader";
import {AwardButtons} from "@/pages/CasePage/ui/AwardButtons/AwardButtons";
import {
    BonusPaymentAwardButtons
} from "@/pages/BonusPaymentCasePage/ui/BonusPaymentAwardButtons/BonusPaymentAwardButtons";

interface BonusPaymentHorizontalRouletteWheelProps {
    className?: string,
    items: CaseItem[],
    award?: CaseItem,
    openCase: () => void,
    isFast: boolean,
    sellAll: () => void,
    changeFactor: (factor: number) => void,
    currentFactor: number,
    casePrice: number
}
export const BonusPaymentHorizontalRouletteWheel = (props: BonusPaymentHorizontalRouletteWheelProps) => {
    const { className,
        items,
        award,
        openCase,
        isFast,
        sellAll,
        currentFactor,
        changeFactor,
        casePrice
    } = props;
    const dispatch = useDispatch()
    const [offset, setOffset] = useState<number>(0)
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [randomRouletteList, setRandomRouletteList] = useState<CaseItem[]>()
    const profile = useSelector(getProfile)

    const rouletteWheelItemsRef = useRef<HTMLDivElement>()
    const [isClickItems, setIsClickItems] = useState({ current: false })
    const soundVolume = useSelector((state: StateSchema) => state.subHeader.sound.volume)
    const sound_CaseOpen = new Audio("/sounds/Case/open.ogg")
    const sound_CaseClick = new Audio( "/sounds/Case/click.mp3" )
    const sound_CaseEnd = new Audio( "/sounds/Case/Case_End.mp3" )

    const getRandomRange = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const createRandomRouletteList = (): CaseItem[] => {
        const result: CaseItem[] = [];

        for (let i = 0; i < 37; i++) {
            const index = getRandomRange(0, items.length - 1);
            const item = items[index];
            result.push(item);
        }

        if (award) result[31] = award;
        return result;
    }


    const itemComponents = randomRouletteList
        ?.map((item, index) => {
            return <HorizontalRouletteWheelItem key={index} caseItem={item} />
        })

    const calcAwardPosition = ():number => {
        const startOfAward = 186 * 30 - 460
        const endOfAward = startOfAward + 175
        return getRandomRange(startOfAward, endOfAward);
    }


    useEffect(() => {
        if (isFast) {
            setRandomRouletteList(createRandomRouletteList)
            onEndHandle()
        } else {
            setRandomRouletteList(createRandomRouletteList)
            setTimeout(() => setOffset(calcAwardPosition), 100)
            setTimeout(() => onItemsPaintedHandle(), 100)
            setTimeout(() => onEndHandle(), 3400)
        }

        return () => {
            if ( !sound_CaseEnd.paused ) {
                sound_CaseEnd.pause()
            }
            sound_CaseEnd.currentTime = 0
        }
    }, [])

    const onItemsPaintedHandle = () => {
        sound_CaseOpen.volume = soundVolume
        sound_CaseOpen.play().catch(e => console.error(e))
        startClickItems()
    }

    const startClickItems = () => {
        const firstItem = rouletteWheelItemsRef.current?.children?.item( 0 )
        if ( firstItem ) {
            setIsClickItems( (prev) => { prev.current = true; return prev } )
            setTimeout(() => {
                const firstItemRect = firstItem.getBoundingClientRect()
                window.requestAnimationFrame( () => onClickItemsHandle( firstItemRect ) )
            }, 100)
        }
    }
    const stopClickItems = () => {
        setIsClickItems( (prev) => { prev.current = false; return prev } )
    }

    function onClickItemsHandle( firstItemRect: DOMRect, positionLast: number = 0 ) {
        if ( isClickItems.current === false ) {
            return
        }

        const positionCurrent = Math.abs(
            parseInt(
                window
                    .getComputedStyle( rouletteWheelItemsRef.current ).right
                    .replace( "px", "" )
            )
        )
        if ( ( positionCurrent - positionLast ) >= firstItemRect.width ) {
            positionLast = positionCurrent

            sound_CaseClick.pause()
            sound_CaseClick.volume = 0.15 * soundVolume
            sound_CaseClick.currentTime = 0
            sound_CaseClick.play().catch(e => console.error(e))
        }

        window.requestAnimationFrame( () => onClickItemsHandle( firstItemRect, positionLast ) )
    }

    const onEndHandle = () => {
        sound_CaseEnd.volume = soundVolume
        sound_CaseEnd.play().catch(e => console.error(e))
        stopClickItems()
        setIsEnd(true)
        setOffset(5208)
        dispatch(liveHeaderActions.setIsPaused(false));
    }

    return (
        <div className={classNames(undefined, {}, [className])}>
            <div className={classNames(cls.HorizontalRouletteWheel, {}, [])}>
                <div className={classNames(cls.HorizontalRouletteWheelInner, {[cls.IsEnd]: isEnd}, []) }>
                    <div className={cls.Cursor}/>
                    {isEnd && <div className={cls.IsEnd}/>}
                    <div ref={rouletteWheelItemsRef} className={classNames(cls.Items, {[cls.IsEnd]: isEnd}, [])} style={{ right: offset }}>
                        {itemComponents}
                    </div>
                </div>
            </div>
            {isEnd && <div className={cls.AwardBlock}>
                <BonusPaymentAwardButtons currentFactor={currentFactor}
                              changeFactor={changeFactor}
                              userSteamId={profile.steamId}
                              awardSumm={award?.price}
                              openCase={openCase}
                              sellAll={sellAll}
                              casePrice={casePrice}
                              isFast={isFast}
                              horizontalVariant={true}
                />
            </div>}
        </div>

    );
};
