import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './SoundButton.module.scss'
import {MiniButton} from "@/shared/ui/MiniButton/MiniButton";
import Sound from "@/assets/svg/subheader/sound.svg";
import SoundOff from "@/assets/svg/subheader/sound-off.svg";
import {SoundInput} from "@/widgets/SubHeader/ui/SoundButton/ui/SoundInput/SoundInput";
import {useEffect, useRef, useState} from "react";

interface SoundButtonProps {
    className?: string,
    onChange: (value: number) => void,
    value: number
}
export const SoundButton = (props: SoundButtonProps) => {
    const { className, onChange, value } = props;
    const ref = useRef<HTMLDivElement>()

    const [inputIsOpen, setInputIsOpen] = useState<boolean>();

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setInputIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputIsOpen]);


    return (
        <div ref={ref} className={classNames(cls.SoundButton, {}, [className])}>
            <MiniButton onClick={() => {setInputIsOpen(!inputIsOpen)}} icon={value === 0 ? SoundOff : Sound}/>
            {inputIsOpen && <div className={cls.Input}>
                <SoundInput value={value} onChange={onChange}/>
            </div>}
        </div>
    );
};
