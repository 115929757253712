import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './BonusPaymentAwardButtons.module.scss'
import {Link, useNavigate} from "react-router-dom";
import {OpenCount} from "@/pages/CasePage/ui/OpenCount/OpenCount";
import {CommonButton} from "@/shared/ui/CommonButton/CommonButton";
import ContractIcon from "@/assets/svg/menu/contract-icon.svg";
import {formatMoney} from "@/shared/lib/formatMoney/formatMoney";
import UpgradeIcon from "@/assets/svg/menu/upgrade-icon.svg";
import {ReopenButton} from "@/pages/CasePage/ui/ReopenButton/ReopenButton";
import RefreshIcon from "@/assets/svg/case/refresh.svg";

interface BonusPaymentAwardButtonsProps {
    className?: string,
    currentFactor: number,
    changeFactor: (factor: number) => void,
    userSteamId: string,
    awardSumm: number,
    openCase: (openCount: number, isFast: boolean) => void,
    sellAll: () => void,
    casePrice: number,
    isFast: boolean,
    horizontalVariant?: boolean}
export const BonusPaymentAwardButtons = (props: BonusPaymentAwardButtonsProps) => {
    const {
        className,
        currentFactor,
        changeFactor,
        userSteamId,
        awardSumm,
        openCase,
        sellAll,
        casePrice,
        isFast,
        horizontalVariant
    } = props;
    const navigate = useNavigate();

    return (
        <div className={classNames(cls.AwardButtons, {}, [className])}>
            <div className={cls.Info}>
                <span>Предметы можно вывести в </span>
                <Link to={`/profile/${userSteamId}`} style={{color: '#FFDA57'}}>Вашем профиле</Link>
            </div>
            <div className={cls.DownButtons}>
                <CommonButton className={cls.SideButtons} icon={ContractIcon} text={`в контракты`} onClick={() => {navigate('/contracts')}}/>
                <div className={cls.SendButtonWrapper}>
                    {!horizontalVariant && <CommonButton mainColor={true} className={cls.SendButton} disabled={awardSumm === 0} text={awardSumm === 0 ? 'Все предметы проданы' : `продать все за ${formatMoney(awardSumm)}`} onClick={sellAll}/>}
                    {horizontalVariant && <CommonButton mainColor={true} className={cls.SendButton} disabled={awardSumm === 0} text={`продать за ${formatMoney(awardSumm)}`} onClick={sellAll}/>}
                </div>
                <CommonButton className={cls.SideButtons} icon={UpgradeIcon} text={`в апгрейды`} onClick={() => {navigate('/upgrades')}}/>
            </div>
        </div>
    );
};
