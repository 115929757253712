import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './UserIcon.module.scss'
import React from "react";

interface UserIconProps {
    className?: string,
    src: string,
    height: number,
    width: number
}
export const UserIcon = (props: UserIconProps) => {
    const { className, src, height, width } = props;
    return (
        <div className={classNames(cls.UserIcon, {}, [className])}>
            <img className={cls.Icon} height={height} width={width} src={src}/>
        </div>
    );
};
