import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './SubHeader.module.scss'
import SupportIcon from "@/assets/svg/subheader/headphones.svg";
import {MiniButton} from "@/shared/ui/MiniButton/MiniButton";
import {SubHeaderButtonPayment} from "@/widgets/SubHeader/ui/SubHeaderButtonPayment/SubHeaderButtonPayment";
import {SubHeaderUserIcon} from "@/widgets/SubHeader/ui/SubHeaderUserIcon/SubHeaderUserIcon";
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "@/entities/User/model/selectors/getUserAuthData/getUserAuthData";
import {Login} from "@/widgets/SubHeader/ui/Login/Login";
import {
    useGetNotificationsQuery,
    useReadNotificationMutation
} from "@/entities/Profile/api/profileApi";
import {SoundButton} from "@/widgets/SubHeader/ui/SoundButton/SoundButton";
import {Link} from "react-router-dom";
import {StateSchema} from "@/app/providers/StoreProvider";
import {subHeaderActions} from "@/entities/SubHeader/slice/sliceSubHeader";
import {clampNumber} from "@/shared/lib/math/math";
import {ExitButton} from "@/widgets/SubHeader/ui/ExitButton/ExitButton";
interface SubHeaderProps {
    className?: string
}
export const SubHeader = (props: SubHeaderProps) => {
    const { className} = props;
    const profile = useSelector(getProfile)
    const soundVolume = useSelector((state: StateSchema) => state.subHeader.sound.volume)
    const dispatch = useDispatch()
    const {data, isLoading, isError} = useGetNotificationsQuery();
    const [readNotificationMutations] = useReadNotificationMutation();

    const readNotification = (id: string) => {
        readNotificationMutations({ids: [id]})
            .unwrap()
            .then()
    }

    const readAllNotifications = () => {
        const unreadNotifications = data
            ?.filter((it) => it.unread === true)
            .map((it) => it.id)

        if (unreadNotifications?.length > 0) {
            readNotificationMutations({ids: unreadNotifications})
                .unwrap()
                .then()
        }
    }

    /**
     * @param i значение от 0 до 100
     */
    const updateSoundVolume = (i: number) => {
        const value = clampNumber(i, 0, 100) / 100
        dispatch(subHeaderActions.setSoundVolume(value))
    }

    return (
        <div className={classNames(cls.SubHeader, {}, [className])}>
            <div className={cls.LeftSide}>
            </div>
            <div className={cls.RightSide}>
                <Link to={'https://t.me/leetcase_manager'}>
                    <MiniButton icon={SupportIcon}/>
                </Link>
                <SoundButton value={soundVolume * 100} onChange={updateSoundVolume} />
                {profile &&
                    <>
                        {/*<NotificationButton readAllNotifications={readAllNotifications} readNotification={readNotification} notifications={data} />*/}
                        <SubHeaderButtonPayment balance={profile.balance} />
                        <SubHeaderUserIcon steamId={profile.steamId} src={profile.icon}/>
                    </>
                }
                {profile && <ExitButton/>}
                {!profile && <Login/>}
            </div>
        </div>
    );
};
