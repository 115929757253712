import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './SteamAuthPage.module.scss'
import React, {useEffect} from "react";
import {useAuthUserMutation} from "@/entities/Profile/api/profileApi";
import {useNavigate} from "react-router-dom";

interface SteamAuthPageProps {
    className?: string,
}
export const SteamAuthPage = (props: SteamAuthPageProps) => {
    const { className } = props;
    const navigate = useNavigate();

    const [authUserMutation] = useAuthUserMutation();

    useEffect(() => {
        authUserMutation(location.search).unwrap()
            .then(() => setTimeout(() => navigate('/'), 3000))
    }, [])
    return (
        <div className={classNames(cls.SteamAuthPage, {}, [className])}>
            <img src={require('@/assets/gif/loading.gif')} alt="loading..." />
        </div>
    );
};

export default SteamAuthPage;