export interface LiveHeaderSchema {
  isPaused: boolean;
  rare?: LiveHeaderResponse[];
  all?: LiveHeaderResponse[];
  rareQueue?: LiveHeaderResponse[];
  allQueue?: LiveHeaderResponse[];
}

export interface LiveHeaderItemsResponse {
  rare: LiveHeaderResponse[],
  all: LiveHeaderResponse[]
}

export interface LiveHeaderResponse {
  from: WonFrom;
  caseImage: string,
  caseId: string,
  item: LiveHeaderItemResponse,
  user: LiveHeaderUserResponse
}

export interface LiveHeaderItemResponse {
  name: string,
  image: string,
  stattrak: boolean,
  rarity: string,
}

export interface LiveHeaderUserResponse {
  steamId: number,
  icon: string,
  name: string,
}

export enum WonFrom {
  CASE = 'CASE',
  PAYMENT_BONUS_CASE = 'PAYMENT_BONUS_CASE',
  UPGRADE = 'UPGRADE',
  CONTRACT = 'CONTRACT'
}