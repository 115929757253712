import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './App.module.scss'
import {Header} from "@/widgets/Header";
import {Menu} from "@/widgets/Menu/ui/Menu";
import React, {ReactNode, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchProfile} from "@/entities/User";
import {SubHeader} from "@/widgets/SubHeader";
import {DownMenu} from "@/widgets/DownMenu";
import 'react-toastify/dist/ReactToastify.css';
import {Bounce, ToastContainer} from "react-toastify";
import {Footer} from "@/widgets/Footer/ui/Footer";
import {useGetStatisticQuery} from "@/entities/Statistic/api/statisticApi";
import {useGetAuthDataQuery} from "@/entities/Profile/api/profileApi";


interface AppProps {
    className?: string,
    children?: ReactNode;

}
export const App = (props: AppProps) => {
    const { className, children } = props;
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const {data} = useGetStatisticQuery();
    useGetAuthDataQuery();

    return (
        <div className={classNames(cls.App, {}, [className])}>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}
            />
            <div className={cls.HeaderBackground}/>
            <Header className={cls.Header} />
            <Menu className={cls.Menu} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen}/>
            <SubHeader className={classNames(cls.SubHeader, {[cls.MenuIsClose]: !menuIsOpen})} />
            <div className={classNames(cls.Content, {[cls.MenuIsClose]: !menuIsOpen})}>
                <div className={cls.OutletWrapper}>
                    <Outlet/>
                </div>
                <Footer statisticInfo={data} className={cls.Footer}/>
            </div>
            <DownMenu className={cls.DownMenu} />
        </div>
    );
};
