import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './MiniButton.module.scss'

interface SubHeaderButtonProps {
    className?: string,
    icon: React.VFC<React.SVGProps<SVGSVGElement>>,
    onClick?: () => void,

}
export const MiniButton = (props: SubHeaderButtonProps) => {
    const { className, icon: Icon, onClick} = props;
    return (
        <div className={classNames(cls.MiniButton, {}, [className])}
             onClick={onClick}>
            <Icon/>
        </div>
    );
};
