
export function formatMoney(money: number): string {
    return new Intl.NumberFormat("ru-RU", { style: "currency", currency: "RUB",minimumFractionDigits: 0,
        maximumFractionDigits: 2 })
        .format(
        money
    ).trimEnd().replace(/\s+(?=\S*$)/, '')
}

export function formatMoneyWithoutDigits(money: number): string {
    return new Intl.NumberFormat("ru-RU", { style: "currency", currency: "RUB", minimumFractionDigits: 0,
        maximumFractionDigits: 0 })
        .format(
            money
        ).trimEnd().replace(/\s+(?=\S*$)/, '')
}