import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './Header.module.scss'
import {LiveHeaderSwitcher} from "./LiveHeaderSwitcher";
import {LiveHeader} from "@/widgets/Header/ui/LiveHeader/ui/LiveHeader";
import {useEffect, useState} from "react";
import {fetchLiveHeaderItems} from "@/entities/LiveHeader";
import {useDispatch, useSelector} from "react-redux";
import {getAllItems, getRareItems} from "@/entities/LiveHeader/model/selectors/getItems/getItems";
import {LiveHeaderItemsResponse} from "@/entities/LiveHeader/model/types/liveHeader";
import {liveHeaderActions} from "@/entities/LiveHeader/model/slice/sliceLiveHeader";
import {IMessage} from "@stomp/stompjs";
import {useLiveHeaderWs} from "@/shared/api/stompClient";

interface HeaderProps {
    className?: string,
}
export const Header = ({className}: HeaderProps) => {
    const [isBest, setIsBest] = useState<boolean>(true);
    const dispatch = useDispatch();

    const items = useSelector(getAllItems);
    const rareItems = useSelector(getRareItems);

    const addItemsFromWs = (response: IMessage) => {
        const data: LiveHeaderItemsResponse = JSON.parse(response.body);
        dispatch(liveHeaderActions.addItems(data));
    };

    useLiveHeaderWs('/topic/live_header', addItemsFromWs);
    useEffect(() => {
        dispatch(fetchLiveHeaderItems());
    }, []);

    return (
        <div className={classNames(cls.Header, {}, [className])}>
            <LiveHeaderSwitcher
                isBest={isBest}
                changeIsBest={setIsBest}
            />
            <LiveHeader items={isBest ? rareItems : items}/>
        </div>
    );
};
