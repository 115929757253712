import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './DownMenuButton.module.scss'
import {Link} from "react-router-dom";

interface DownMenuButtonProps {
    className?: string,
    href: string,
    icon: React.VFC<React.SVGProps<SVGSVGElement>>,
    active?: boolean
}
export const DownMenuButton = (props: DownMenuButtonProps) => {
    const { className, icon: Icon, href, active } = props;
    return (
        <Link className={classNames(cls.DownMenuButton, {[cls.active]: active}, [])} to={href}>
            {active &&
                <div className={cls.Active}>
                    <div className={cls.ActiveLine}/>
                    <div className={cls.Blur}/>
                </div>
            }
            <Icon className={classNames(cls.Image, {}, [])} />
        </Link>
    );
};
