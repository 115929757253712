import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './LiveHeaderSwitcher.module.scss'
import SwitcherCommon from "@/assets/svg/header/switcher-common.svg"
import SwitcherTop from "@/assets/svg/header/switcher-top.svg"
import {HeaderSwitcherButton} from "../../HeaderSwitcherButton";

interface LiveHeaderSwitcherProps {
    className?: string,
    isBest: boolean,
    changeIsBest: (isBest: boolean) => void,
}
export const LiveHeaderSwitcher = (props: LiveHeaderSwitcherProps) => {
    const { changeIsBest, isBest, className } = props
    return (
        <div className={classNames(cls.LiveHeaderSwitcher, {}, [className])}>
            <HeaderSwitcherButton onClick={() => changeIsBest(true)} active={isBest} title={'ТОП'} icon={SwitcherTop} />
            <HeaderSwitcherButton onClick={() => changeIsBest(false)} active={!isBest} title={'ВСЕ'} icon={SwitcherCommon} />
        </div>
    );
};
