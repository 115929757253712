import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './Loading.module.scss'
import React from "react";

interface LoadingProps {
    className?: string,
}
export const Loading = (props: LoadingProps) => {
    const { className } = props;
    return (
        <div className={classNames(cls.Loading, {}, [className])}>
            <img src={require('@/assets/gif/loading.gif')} alt="loading..." />
        </div>
    );
};
