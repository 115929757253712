import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './DropdownButton.module.scss'
import DropdownButtonSvg from '@/assets/svg/menu/dropdown-button.svg'


interface DropdownButtonProps {
    className?: string,
    onClick: () => void,
    active: boolean
}
export const DropdownButton = (props: DropdownButtonProps) => {
    const { className, onClick , active} = props;
    return (
        <div onClick={onClick} className={classNames(cls.DropdownButtonWrapper, {[cls.Active]: active}, [])}>
            <div className={cls.DropdownButton}>
                <div className={cls.Box} />
                <div className={cls.Box} />
                <div className={cls.Box} />
                <div className={cls.Box} />
            </div>
        </div>
    );
};
