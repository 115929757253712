import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './BonusPaymentTimer.module.scss'
import {useEffect, useState} from "react";

interface BonusPaymentTimerProps {
    className?: string,
    seconds: number
}
export const BonusPaymentTimer = (props: BonusPaymentTimerProps) => {
    const { className , seconds} = props;
    const [timeLeft, setTimeLeft] = useState(seconds);
    useEffect(() => {
        if (timeLeft <= 0) return;

        const interval = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
    };
    return (
        <div className={classNames(cls.BonusPaymentTimer, {}, [className])}>
            <span className={cls.Timer}>{formatTime(timeLeft)}</span>
            <span className={cls.Description}>до обнуления счетчика</span>
        </div>
    );
};
