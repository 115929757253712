import {classNames} from "@/shared/lib/classNames/classNames";
import cls from './Footer.module.scss'
import React from "react";
import {AdditionalInfo} from "@/widgets/Footer/ui/AdditionalInfo/ui/AdditionalInfo";
import {InformationBlock} from "@/widgets/Footer/ui/InformationBlock/InformationBlock";
import Case from "@/assets/svg/subheader/case.svg";
import Contract from "@/assets/svg/subheader/contracts.svg";
import Users from "@/assets/svg/subheader/users.svg";
import Online from "@/assets/svg/subheader/online.svg";
import {StatisticResponse} from "@/entities/Statistic/model/types/statistic";

interface FooterProps {
    className?: string,
    statisticInfo?: StatisticResponse
}
export const Footer = (props: FooterProps) => {
    const { className, statisticInfo } = props;
    return (
        <div className={classNames(cls.Footer, {}, [className])}>
            <AdditionalInfo className={cls.AdditionalInfo} />
            <div className={cls.Stat}>
                <div className={cls.InformationBlocks}>
                    <div className={cls.InformationBlocksRow}>
                        <InformationBlock icon={Case} title={"открыто кейсов"} value={statisticInfo?.casesOpenCount ? statisticInfo.casesOpenCount : 0} />
                        <InformationBlock className={cls.LineSecondElem} icon={Contract} title={"контракты"} value={statisticInfo?.contractsCount ? statisticInfo.contractsCount : 0} />
                    </div>
                    <div className={cls.InformationBlocksRow}>
                        <InformationBlock icon={Users} title={"пользователей"} value={statisticInfo?.usersCount ? statisticInfo.usersCount : 0 } />
                        <InformationBlock className={cls.LineSecondElem} icon={Online} title={"онлайн"} value={statisticInfo?.onlineCount ? statisticInfo.onlineCount : 0} />
                    </div>
                </div>
                <div className={cls.UL}>
                    <h1 className={cls.H1}>
                        Кейсы КС2
                    </h1>
                    <h2 className={cls.H1}>
                        Открывай кейсы кс2 вместе с leetcase
                    </h2>
                </div>
            </div>
            <div className={cls.Background}/>
        </div>
    );
};
